import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import styled from "styled-components"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"

const E = {
  Content: styled.div`
    overflow: hidden;
    z-index: 2;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 40px 5px 40px 40px;
    padding: 30px;
    margin: 100px 0;

    h3 {
      padding: 10px 0;
      @media (max-width: ${breakpoints.large}px) {
        text-align: center;
      }
    }
    p {
      padding: 10px 0;
      line-height: 1.5rem;
      width: 100%;
    }

    h2 {
      font-size: 4rem;
      @media (max-width: ${breakpoints.medium}px) {
        font-size: 3rem;
      }
    }
  `,
}

const DiscPage = () => (
  <Layout>
    <Seo title="מדיניות פרטיות" />
    <Head title="מדיניות פרטיות" />

    <section>
      <div className="container">
        <E.Content>
          <h2>מדיניות פרטיות ותנאי שימוש באתר פולאריס</h2>
          <p>
            אנו מודים לך כי בחרת להשתמש באתר האינטרנט של פולאריס סוכנות לביטוח
            פנסיוני (2018) בע"מ' (להלן: "הסוכנות")
            <br />
            מסמך זה מנוסח בלשון זכר מתוך טעמי נוחות אך מיועדים לגברים ולנשים
            כאחד.
            <br />
            אנא קרא בעיון רב את תנאי השימוש. מסמך זה מהווה הסכם לשימושך באתר
            ובאזור האישי, ובמידע שבאתרים אחרים המקושרים לאתר זה (בין בהפניה
            ישירה לאתרים אחרים ובין באופן עקיף), לקבלת מידע על המוצרים שברשותך.
            <br />
            אם אינך מסכים לאיזה מהתנאים האמורים בתנאי השימוש האלה אנא הפסק את
            השימוש באתר ואל תעשה בו כל שימוש, לרבות לא במידע ובתכנים שבו.
            <br />
            במסירת המידע לצורך קבלת הצעות וטיפול במוצרים שלכם, הנכם מאשרים,
            שהמידע נמסר מרצונכם ובהסכמתכם. המידע והנתונים שמסרתם וכל עדכון שלהם
            יישמרו במאגרי המידע של הסוכנות וישמשו למתן ההצעות, הפקה וחידוש
            פוליסות, ניהול שוטף של תיק הביטוח שלכם, מתן שירות לקוחות ויצירת קשר
            למתן מידע בקשר עם מתן המוצרים והשירותים בהתאם לפרטים שמסרתם.
            <br />
            אם הסוכנות תארגן את פעילותה במסגרת תאגיד אחר או תתמזג עם גוף אחר,
            היא תהיה זכאית להעביר לתאגיד החדש העתק מן המידע שנאגר אודותיכם ובלבד
            שתאגיד זה יקבל על עצמו כלפי את הוראות הצהרה זו.
            <br />
            הסוכנות תעביר מידע אישי לגורמים הבאים: חברות ביטוח, בתי השקעות,
            שותפים עסקיים, לצורך קבלת הצעות לביטוח ולקיום חובות המוטלות על
            הסוכנות על פי דין. הסוכנות תעשה שימוש במידע אך ורק למטרה שלשמה הוא
            נמסר.
            <br />
            כל אדם זכאי לעיין במידע אישי אודותיו המוחזק בידי הסוכנות; הסוכנות
            תאפשר לנשוא המידע לעיין במידע אישי אודותיו לאחר הגשת בקשה בהתאם
            להוראות החוק, כל עוד לא קיימת עילה שבדין לדחיית הבקשה. נשוא המידע
            זכאי להגיש בקשה בכתב לתיקון מידע אישי או למחיקת מידע אישי שאינו
            נכון, שלם, ברור או מעודכן, והסוכנות תתקן את המידע האישי כל עוד לא
            קיימת עילה שבדין לדחיית הבקשה.
            <br />
            <br />
            <b>
              נשוא המידע זכאי לדרוש בכתב שמידע אישי אודותיו יימחק ממאגר המידע.
              בקשות למימוש זכות העיון, תיקון או התנגדות לדיוור ישיר יש להפנות ל-
              עומר הרם, מנכ"ל בדוא"ל: omer@polaris-fin.co.il
            </b>
            <br />
            <br />
            הסוכנות תשיב על בקשה למימוש זכות העיון במהירות האפשרית ובכל מקרה לא
            יאוחר מתוך 30 יום מיום שנתקבלה.
            <br />
            הסוכנות אוספת מידע לגבי המבקרים והמשתמשים באתר מתוך מטרה לשפר את
            איכות וטיב השירותים שהיא מעניקה באמצעות האתר. המדובר במידע כללי על
            המבקרים באתר ומידע שאינו מכיל פרטים אישיים. מידע כללי אודות המבקרים
            באתר נאסף על בסיס מצטבר ואנונימי , דהיינו באופן שלא ניתן לשייך כל
            מידע מזהה ופרטים אישיים כלשהם למידע האמור ולהיפך. המידע הכללי לגבי
            המבקרים באתר נאסף לשם שיפור תוכן האתר, איכותו וטיב השירותים הניתנים
            באמצעותו.
            <br />
            האתר מכיל קישורים ("לינקים") לאתרי אינטרנט של צדדים שלישייםוספקי
            שירות. הסוכנות אינה אחראית למדיניות השמירה על הפרטיות ואבטחת המידע
            של אתרים אלה. המדיניות המתוארת במסמך זה תקפה לגבי כל השירותים של
            הסוכנות בלבד, אלא אם נקבעה מדיניות אחרת לגבי אותו שירות. אתרים
            מקושרים אלה ניתנים אך ורק לנוחיותכם ולא כהמלצה של הסוכנות לגבי תוכנם
            של אתרים מקושרים כאלה. הסוכנות אינה מייצגת ואינה אחראית לנכונות,
            הדיוק, רמת הביצועים או האיכות של כל תוכן, תוכנה, שירות או יישום
            הנמצאים באתר מקושר כלשהו. הסוכנות אינה אחראית לזמינות של האתרים
            המקושרים או לתוכנם או פעילויותיהם של אתרים כאלה.
            <br />
            על מנת להעניק שירות מהיר ויעיל יותר, לזהותכם בכניסה חוזרת לאתר מבלי
            למלא מחדש פרטים אישיים שונים שנתבקשתם למלא בעבר, הסוכנות עושה שימוש
            בטכנולוגית Cookies / עוגיות שהיא סימון פנימי ייחודי הנרשם בדפדפן של
            הגולשים. כלים אוטומטיים אלו אוספים מידע כללי אודות משתמשי האתר
            ואודות שימושם באתר. הטכנולוגיה מאפשרת בין היתר לנטר את הפעילות שהנכם
            מבצעים באתר ולשפר את חווית הגלישה והשירות באופן פרסונלי עבורכם.
            באמצעות טכנולוגיה זו הסוכנות מקבלת מידע אודות פעילותכם באתר וכן מידע
            לגבי המחשב או הטלפון החכם ממנו נעשית הגלישה כגון: גרסת מערכת ההפעלה,
            סוג מסך, דפדפן, כתובת IP זמן שהות באתר או בדף כלשהו, רמת טעינת
            הסוללה, מיקום ועוד. על בסיס המידע שנאסף באמצעות טכנולוגיה זו הסוכנות
            רשאית לפנות אליכם בין היתר למטרות שירות, שיווק (בכפוף להוראות הדין)
            ולמטרות אחרות על בסיס הפעולות שביצעתם באתר או על בסיס מידע המתקבל
            מהעוגיות או באמצעותן.
          </p>
          <h3>הימנעות מ"פישינג"</h3>
          <p>
            הסוכנות מקפידה לבצע רישום מפורט של הפעולות המבוצעות במערכותיה ולשמור
            אותן לאורך זמן. כמו כן מופעלים בסוכנות אמצעי ניטור והקלטה שמאפשרים
            איתור פעולות חריגות ותיחקורן בהתאם לדרישות חוק הגנת הפרטיות ותקנות
            אבטחת מידע.
            <br />
            <br />
            <b>
              הסוכנות לא תפנה לעולם ללקוחותיה בדוא"ל בבקשה למסור את הקוד האישי
              הסודי שלהם או כל פרט אישי או מזהה אחר, לרבות פרטי אשראי באמצעות
              המייל.
            </b>
            <br />
            <br />
            <b>
              לכן אין להיענות בדוא"ל לכל בקשה מסוג זה המתקבלת באמצעות דוא"ל "בשם
              הסוכנות" ואין למסור את שם המשתמש והסיסמא או כל פרט מזהה אחר לאף
              גורם.
            </b>
            <br />
            <br />
            אם קיבלתם הודעה "דחופה" המורה לכם למסור את פרטי האימות האישיים שלכם
            תוך איום בפגיעה בשירות אם לא תמסרו את הפרטים, או הודעה המאיצה בכם
            להחליף סיסמא או למסור אותה מסיבה כלשהי, זוהי הודעה חשודה ואסור לכם
            להיענות לה. בדקו את הכתובת שממנה הגיעה ההודעה ודווחו לנו ללא דיחוי
            על ההודעה החשודה לכתובת: omer@polaris-fin.co.il
            <br />
            במידה ומסרתם באתר המתחזה מידע הקשור לחשבון בנק או כרטיס אשראי, יש
            ליידע את הבנק או את חברת האשראי ולקבל מהם את הנחיותיהם. במידה ונמסר
            באתר המתחזה שם משתמש ו/או סיסמא לאתר, יש להחליפם באופן מידי.
            <br />
            הסוכנות משתמשת באמצעים מתקדמים לשם אבטחת המידע באתר, בהתאם לנהוג
            ולמקובל באתרים מסוג זה. הסוכנות אינה מבטיחה ואיננה ערבה לכך, כי מסדי
            הנתונים שלה, לרבות מידע שאתה מסרת, לא יפרצו וכי מידע כזה יגיע לצד ג'
            שאיננו מורשה וזאת, כל עוד היא תנקוט באמצעים סבירים. הסוכנות לא תהיה
            אחראית לכל נזק, מכל מין וסוג שהוא, שייגרם לך כתוצאה מפריצה למסדי
            הנתונים של הסוכנות ו/או למחשבים שלה, כתוצאה ממקרים שאינם בשליטתה
            ו/או הנובעים מהעברת הפרטים לחברות הביטוח ו/או ספק האזור האישי, מקרים
            שהם בגדר כוח עליון ו/או כתוצאה מאובדן המידע
          </p>
          <h3>שימוש באזור האישי</h3>
          <p>
            באמצעות האזור האישי תוכלו לצפות במידע השמור והמוצרים שרכשתם באמצעות
            הסוכנות. בעת רישום ראשוני לאזור האישי תתבקשו להזין פרטים אישיים כגון
            מספר תעודת זהות, כתובת מייל ותאריך לידה. בשלב זה תקבלו מייל מאת הספק
            החיצוני המתפעל את האזור האישי עבור הסוכנות. במייל תתבקשו לבחור סיסמא
            ראשונית. בכניסה הבאה לאזור האישי תתבקשו להחליף סיסמא ולהזין את
            הסיסמא החדשה בצירוף מספר תעודת זהות.
            <br />
            הפרטים האישיים הנ"ל ישמשו אך ורק לצורך זיהוי ואימות הזיהוי לצורך מתן
            גישה. חשוב לציין, כי אינכם חייבים למסור את הפרטים אך ללא מסירת
            הפרטים לא תוכלו להשתמש באזור האישי.
            <br />
            במסירת הפרטים, הנך נותן הסכמתך לסוכנות לאסוף ולהפיק את המידע ,
            ולעשות שימוש במידע , כדי לאפשר לך להשתמש באתר ובאזור האישי, לרבות
            העברתו לחברות הביטוח, או לרגולטורים ורשויות החוק בכפוף להצגת צו
            שיפוטי או בכפוף לכל חוק או תקנה המורים לסוכנות לעשות כן.
            <br />
            כל הפרסומים באתר זה הינם לידיעה בלבד, ואינם מהווים ואין לראות בהם
            בשום דרך המלצה, חוות דעת, שיווק, או ייעוץ, ואין לראות בהם התחייבות
            למתן שירות כתחליף להליך המתחשב בנתוני ובצרכים האישיים והספציפיים של
            כל לקוח, אשר ייערך על ידי חברת הביטוח בה יבחר לרכוש את הפוליסה. בכל
            מקרה של סתירה בין המוצג באזור האישי לבין התעריפים של חברת הביטוח,
            יקבעו תעריפי חברת הביטוח או הרשום בספרי חברת הביטוח ולסוכנות לא
            תהייה אחריות לגבי קביעתה הסופית של חברת הביטוח.
          </p>
          <h3>הצהרות המשתמש</h3>
          <p>
            ההחלטה להשתמש באתר או באזור האישי והאחריות על תוצאות השימוש בהם הנה
            על המשתמש בלבד, הסוכנות לא תישא בשום אחריות בגין נזק, הפסד או אובדן
            כלשהו, הנובעים או קשורים בכל דרך שהיא לשימוש באתר, לעיכוב בשימוש בו,
            או לאי-יכולת להשתמש בו מכל סיבה שהיא.
            <br />
            המשתמש אחראי על אבטחת המידע המצוי על גבי מחשבו ו/או מכשירו הסלולארי/
            ו/או על כל מכשיר אחר באמצעותו יעשה המשתמש שימוש באתר, על ידי שימוש
            באמצעים מקובלים וסבירים כגון תוכנת "אנטי-וירוס", שימוש בסיסמא להגנה
            על קבציו ונעילת מכשירו על מנת למנוע שימוש של צדדים שלישיים במחשבו
            ו/או במכשירו הסלולארי.
            <br />
            המשתמש מתחייב שלא לעשות שימושים אסורים באתר או באזור האישי. כל
            הזכויות בתוכן המופיע באתר שייכים במלואם לחברה, לרבות זכויות יוצרים,
            זכויות הפצה וכל קניין רוחני אחר. התוכן נועד לשימושו האישי של המשתמש
            בלבד, ואין לעשות בו שימוש אחר כלשהו, לרבות שימוש מסחרי. אין להעתיק,
            לשכפל ו/או להדפיס מידע כלשהו לצורך הפצה או פרסום בדרך כלשהי, בלא
            קבלת הסכמתה של הסוכנות, מראש ובכתב. המשתמש מתחייב כי לא יפרסם ברבים
            את המידע ו/או כל חלק ממנו. יובהר, כי תנאים אלה חלים על מכלול
            השימושים האפשרי באתר או באזור האישי, באמצעות מחשב או טלפון סלולארי
            או מכשיר אחר.
            <br />
            המשתמש מתחייב בזאת לשפות ולפצות את הסוכנות או מי מטעמה, בגין כל נזק,
            הוצאה או הפסד, ישיר או עקיף, לרבות הוצאות משפט ושכ”ט עורכי דין,
            שיגרמו לה בקשר עם הפרת תנאי כלשהו מתנאי השימוש, או ביצוע כל פעולה
            אחרת בניגוד לדין בקשר עם השירות. למען הסר ספק מובהר בזאת, כי כל
            שימוש אשר נועד להגביל, להפריע או למנוע את פעילות האתר ו/או את שימושם
            של משתמשים אחרים באתר, הינו אסור בהחלט ומהווה הפרה של תקנון השימוש
            של האתר ושל תנאי השימוש הללו. הסוכנות תהיה רשאית לפעול על פי שיקול
            דעתה הבלעדי, ובכפוף לכל דין על מנת למנוע כל פעילות מפרה, וזאת מבלי
            שפעולות כאמור ייגרעו מכל סעד אחד העומד לסוכנות לפי כל דין כנגד
            השימוש המפר.
            <br />
            בכפוף לתנאי השימוש הללו, כל אדם, שהינו בגיר, ובעל כשרות משפטית רשאי
            לעשות שימוש באתר ובאזור האישי לשם קבלת מידע על שירותי ביטוח המסופקים
            במסגרת האתר.
            <br />
            הסוכנות שומרת לעצמה את הזכות לברור לאיזה משתמשים להגיש הצעות והיא
            יכולה, על פי שיקול דעתה הבלעדי ומכל סיבה שהיא לסרב להציע הצעות
            לביטוח.
          </p>
          <h3>זכויות שימוש וקניין רוחני </h3>
          <p>
            השימוש באתר ובאזור האישי באתר, מותר אך ורק למטרות חוקיות ולמטרות
            שלשמן נועד האתר. כל שימוש ו/או ניסיון לשימוש בסיסמא כוזבת , התחזות
            לאחר או חדירה בכל דרך שהיא למידע ו/או לנתונים אודות צד ג' כלשהו
            אסורים בהחלט.
            <br />
            משתמשים מתחת לגיל שמונה עשרה וחוסים אינם רשאים להשתמש באזור האישי
            בעצמם, אלא באמצעות אפוטרופוסם החוקי.
            <br />
            האתר והאזור האישי מכילים חומרים שונים המוגנים ע"י זכויות יוצרים,
            סימני מסחר ושאר זכויות קנייניות, לרבות תוכן, תוכנה, תמונות, וידאו,
            גרפיקה, מוסיקה וקול, אשר חלקם בבעלות החברה וחלקם בבעלותם של צדדים
            שלישיים. יתרה מזאת, כל האתר, על כל תוכנו מוגן באמצעות זכות יוצרים
            כיצירה שלמה. המשתמש אינו רשאי לשנות, לפרסם, לשדר או להעביר, לקחת חלק
            בהעברה או מכירה, להשתמש באתר או בכל חלק ממנו לשם יצירת יצירה נגזרת
            או לנצל את האתר, ו/או את תוכנו של האתר כולו, או חלקו למטרה כלשהי,
            זולת זו שלשמה נועדו. המשתמש רשאי להעביר נתוני מידע מהאתר אל המחשב
            שלו (download)או "שליפה" לצורך שימושו האישי בלבד.
            <br />
            מבלי לגרוע מכלליות האמור לעיל, אין לעשות שימוש באתר ובתכניו, להעלות
            לאתר (Upload), לשלוף, לשדר להפיץ, לפרסם מידע, או חומר אחר העלול
            להגביל או למנוע מאחר את השימוש באתר.
            <br />
            למעט שליפה לצורך שימושו האישי של המשתמש כאמור ו/או בהתאם למותר
            במפורש עפ"י דיני זכויות היוצרים, אין המשתמש רשאי לבצע כל העתקה,
            הפצה, שידור, העברה, פרסום או ניצול מסחרי אחר של נתוני מידע שהועברו
            מהאתר אל מחשבו, ללא אישור מראש ובכתב מאת החברה, או בעל זכויות
            היוצרים במקרה ואין זו החברה. בכל מקרה שבו המשתמש העתיק, הפיץ, שידר,
            העביר, פרסם או ניצול מסחרי אחר של נתוני המידע שהועברו מהאתר אל
            מחשבו, באופן חוקי, אין המשתמש רשאי לשנות, או למחוק את שמו של בעל
            זכות היוצרים במידע האמור, סימני מסחר או סימני הגנת זכויות היוצרים
            המופיעים על גפי המידע האמור. המשתמש מכיר בכך שאין בביצוע שליפה ו/או
            כל פעולה חוקית אחרת באתר בכדי להקנות לו זכות קניינית כלשהי במידע
            האמור בפרט ובאתר ובתוכנו של האתר בכלל.
            <br />
            נוסף על כך, אינך רשאי לנסות לפגוע באבטחת המידע, לנסות לזהות חולשות
            באבטחת המידע, או לנסות לבצע כל צורה של בדיקה, סריקה, איסוף נתונים,
            אחזור מידע, או פריצה לאתר או למערכותיו.
            <br />
            מבלי לגרוע מכל סעד העומד לזכות הסוכנות על פי כל דין, הסוכנות רשאית
            להפסיק לאלתר גישת משתמש אשר פעל בניגוד לתנאי השימוש באתר, ללא כל
            הודעה מוקדמת.
            <br />
          </p>
          <h3>הגבלת אחריות </h3>
          <p>
            המשתמש מכיר בכך ומסכים כי האתר בכללותו, כולל כל המידע המופיע בו ו/או
            כל חומר או מידע שהגישה אליו נעשית באמצעות האתר, השירות המוענק
            באמצעות השימוש באתר והתוכנות העומדות בבסיסו, מוגשים ומועמדים לרשותו
            כמות שהם (AS IS) וכי בכפוף להסתייגויות של הוראות כל דין המורות אחרת,
            אין לראות במידע המסופק באמצעות האתר משום מתן התחייבות ו/או מצג
            כלשהם, בין אם במפורש, מכללא או מכוח כל דין, כלפי המשתמש ו/או צדדיים
            שלישיים כלשהם, לרבות מצגים ו/או התחייבויות בנוגע לאיכותו, אמינותו,
            מידת הדיוק, השלמות ורמת העדכון של המידע האמור וכן שימושו המסחרי ו/או
            התאמתו למטרה מסוימת כלשהי.
            <br />
            המשתמש מצהיר בזאת ומסכים כי הסוכנות ו/או כל גוף או אדם הקשורים ו/או
            מעורבים ביצירת, הפקת או הפצת האתר, האזור האישי ותוכנם, יהיו פטורים
            מכל אחריות וחבות תחת כל עילה משפטית שהיא, בקשר לכל נזק, פגיעה, הפסד
            או הוצאה, ישירים או עקיפים, משניים, מיוחדים או תוצאתם, הקשורים בקבלת
            המידע המצוי באתר, ו/או השירותים הניתנים באמצעות האתר ו/או בשימוש
            באתר או חוסר היכולת להשתמש באתר, לרבות אך לא רק, אובדן נתונים או
            רווחים, שייגרמו כתוצאה מכל כשל, שגיאה, שיבוש השמטה, הפרעה, מחיקה,
            פגם, איחור או עיכוב של כל פעולה או העברת נתונים, נזקי חומרה ו/או
            תוכנה הנובעים מווירוסים כלשהם, נתק תקשורת כלשהו, גניבה, השמדה, קיום
            גישה בלתי חוקית לכל מידע המצוי באתר, ו/או לכל שינוי או שימוש בנתונים
            ובמידע המצויים באתר או בסימולטור ו/או כל התנהגות פוגעת ו/או בלתי
            חוקית של משתמשים אחרים ו/או צדדים שלישיים כלשהם, וכי כל אחריות ו/או
            סיכון בקשר עם כל האמור לעיל יחולו על המשתמש בלבד. המשתמש מבין ומסכים
            כי כל האמור לעיל חל לגבי כל תחולתו וכל תוכנו של האתר.
            <br />
            הסוכנות לא תהא אחראית, בכל מקרה ועל פי תיאוריה משפטית כלשהי (לרבות
            נזיקית, חוזית, עשיית עושר וכדומה), כלפי המשתמש ו/או כלפי כל אדם אחר,
            במישרין ובעקיפין, לכל נזק ישיר או עקיף, שייגרם למשתמש, לרבות בגין
            אובדן מידע ו/או כתוצאה מניתוקים, שיבושים ו/או תקלות אחרות בקשר עם
            העברת מידע באופן ממוחשב.
            <br />
            הסוכנות לא תהיה אחראית לכל נזק מכל מין וסוג שהוא שייגרם למשתמש ו/או
            למחשב ו/או לציוד אחר של המשתמש כתוצאה מהשימוש שלו באתר ו/או כתוצאה
            משמירת מידע במחשבו של המשתמש, לרבות תוכנות ושורות קוד, אשר הגיעו
            ממחשביה של הסוכנות.
            <br />
            על הסכם זה יחול הדין הישראלי והוא יפורש על פיו. לבית המשפט המוסמך
            באזור תל אביב תהא סמכות השיפוט היחידה והבלעדית בכל עניין הנובע מתנאי
            השימוש ומהתנאים המיוחדים או בקשר אליהם.
            <br />
          </p>
          <h3>שינוי באתר ו/או בתנאי השימוש</h3>
          <p>
            הסוכנות רשאית בכל עת, על-פי שיקול דעתה הבלעדי והמוחלט, ללא צורך במתן
            הודעה מוקדמת, לשנות ו/או להפסיק את מתן השירות באמצעות האתר או אפשרות
            השימוש באתר ו/או כל חלק מהם לרבות בכל הקשור לתוכן האתר ו/או האזור
            האישי, השעות בהן ניתן להשתמש באתר ו/או את השירות או הציוד הדרוש לשם
            קבלת גישה לאתר ו/או לצורך השימוש בו.
            <br />
            כן, רשאית הסוכנות, בכל עת, על-פי שיקול דעתה הבלעדי והמוחלט לשנות,
            לתקן כמו גם לבטל את ההוראות והתנאים האמורים או כל חלק מהם, או להוסיף
            ולכלול במסגרת תנאי שימוש אלה הוראות ותנאים חדשים לפיהם יינתן למשתמש
            השירות ו/או יתאפשר השימוש באתר.
          </p>
          <p>
            בברכת גלישה מהנה ומועילה.
            <br />
            <b>ט.ל.ח</b>
            <br />
            <b>עודכן לאחרונה: 14 ביוני 2021</b>
          </p>
        </E.Content>
      </div>
    </section>
  </Layout>
)

export default DiscPage
