import styled from "styled-components"
import breakpoints from "../GlobalStyle/globals/breakpoints"
import bgImg from "../../../assets/images/pic3.jpg"

const Root = styled.div`
  min-height: 220px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ img }) => (img ? img : bgImg)});

  &.heads {
    @media (min-width: ${breakpoints.large}px) {
      background-position: 0 15%;
      min-height: 320px;
      background-size: 100%;
    }
  }

  @media (min-width: ${breakpoints.large}px) {
    margin-top: 90px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 140%;
    height: 140%;
    left: -20%;
    top: -20%;
    right: -20%;
    bottom: -20%;
    background-image: linear-gradient(135deg, #c6bada 0%, #c0cff3 100%);
    z-index: 2;
    opacity: 0.2;
  }

  h1 {
    font-size: 4.5rem;
    display: block;
    margin-top: 120px;
    text-align: center;
    color: #ffff;
    text-shadow: 2px 1px #c0cff3;
    position: relative;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      color: #fff;
    }

    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 30px;
      border-radius: 4px;
      background-color: #fff;
      margin: 0 auto;
    }

    @media (max-width: ${breakpoints.small}px) {
      font-size: 3rem;
    }
  }
`

export default {
  Root,
}
