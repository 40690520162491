import React from "react"
import E from "./Head.style"

const Head = ({ title, img, className }) => {
  return (
    <E.Root img={img} className={className}>
      <div className="container">
        <h1>{title}</h1>
      </div>
    </E.Root>
  )
}

export default Head
